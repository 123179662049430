@font-face {
  font-family: "OpenSans";
  src: url("../fonts/open-sans/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "OpenSansSemibold";
  src: url("../fonts/open-sans/OpenSans-Semibold.ttf");
}
@font-face {
  font-family: "OpenSansBold";
  src: url("../fonts/open-sans/OpenSans-Bold.ttf");
}
@font-face {
  font-family: "OpenSansExtraBold";
  src: url("../fonts/open-sans/OpenSans-ExtraBold.ttf");
}
@font-face {
  font-family: "Georgia-Regular";
  src: url("../fonts/georgia/Georgia-Regular-font.ttf");
}
@font-face {
  font-family: "georgia-italic";
  src: url("../fonts/georgia/georgia-italic.ttf");
}
@font-face {
  font-family: "georgia-bold";
  src: url("../fonts/georgia/georgia-bold.ttf");
}
@font-face {
  font-family: "georgia-italic-bold";
  src: url("../fonts/georgia/georgia-bold-italic.ttf");
}

$OpenSans: 'OpenSans';
$OpenSansSemibold: 'OpenSansSemibold';
$OpenSansBold: 'OpenSansBold';
$OpenSansExtraBold: 'OpenSansExtraBold';
$georgia_regular: 'Georgia-Regular';
$georgia_italic: 'georgia-italic';
$georgia_bold: 'georgia-bold';
$georgia_italic_bold: 'georgia-italic-bold';

#admin-bar {
	z-index: 9999;
}
body {
	font-family: $OpenSans;
	margin-top: 109px;
}
.scroll_to_top {
	display: none !important;
}
.decorate {
	background: $light-blue;
	color: $white;
	font-family: $OpenSansSemibold;
	padding: 5px 15px;
	&:hover {
		color: $white;
		text-decoration: none;
	}
}
p {
	font-size: $tiny-font;
}
a {
	color: $light-blue;
}
em {
	font-family: $georgia_italic;
}
label {
	margin: 0;
}
.page-title {
	color: $light-blue;
	font-family: $OpenSansBold;
	font-size: $medium-font;
	margin-bottom: 30px;
}
.form-submit {
	background: $blue;
	border: 0;
	color: $white;
	padding: 5px 30px;
}
.breadcrumb {
	li {
		color: $gray;
		font-size: $x-tiny-font;
		a {
			color: $gray;
		}
		span {
			color: $light-blue;
		}
	}
}
article {
	.field-item {
		h3 {
			color: $black;
			font-family: $OpenSansBold;
			font-size: $x-small-font;
			margin-bottom: 30px;
		}
		h4 {
			color: $light-blue;
			font-family: $OpenSansBold;
			font-size: $tiny-font;
			margin-bottom: 30px;
		}
		ul {
			background: #DAECF6;
			margin: 0;
			padding: 30px 30px 30px 40px;
			li {
				padding: 10px 0;
			}
		}
		strong {
			color: $light-blue;
			font-family: $OpenSansBold;
		}
	}
}
.header_bg {
	background-position: center top;
	background-repeat: no-repeat;
	background-size: cover;
	display: none;
	height: 250px;
}
.field-name-field-header-image {
	display: none;
}
.front {
	.l-top {
		background: $blue;
	}
}
.l-top {
	background: $white;
	box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.2);
	overflow: hidden;
	position: relative;
	transition: 0.5s;
	.container-fluid {
		padding: 0;
		.block-hero {
			margin: 0;
			padding: 100px 30px 100px;
			.contextual-links-wrapper {
				right: 20px;
			}
			.block-title {
				color: $white;
				font-family: $georgia_italic;
				font-size: 56px;
				line-height: 60px;
				margin: 0 auto 50px;
				max-width: 600px;
				padding: 0 30px;
				text-align: left;
				@include md {
					font-size: $x-big-font;
				}
			}
			p {
				font-family: $georgia_italic;
				font-size: $small-font;
				margin: 0 auto;
				max-width: 600px;
				padding: 30px 30px 50px;
				position: relative;
				text-align: left;
				&:before {
					border-bottom: 1px solid $white;
					border-left: 1px solid $white;
					border-top: 1px solid $white;
					content: "";
					height: 100%;
					left: 0;
					position: absolute;
					top: 0;
					width: 20px;
				}
				&:after {
					border-bottom: 1px solid $white;
					border-right: 1px solid $white;
					border-top: 1px solid $white;
					content: "";
					height: 100%;
					position: absolute;
					right: 0;
					top: 0;
					width: 20px;
				}
				a {
					color: $white;
					float: right;
					font-family: $georgia_bold;
					font-size: $tiny-font;
					font-style: normal;
					font-weight: bold;
				}
			}
		}
		.block-block-front-top-left-block {
			padding: 40px 15px;
			p {
				color: $off-white;
				font-size: $tiny-font;
				line-height: 26px;
				margin: 0;
			}
			@include md {
				padding: 40px 30px;
			}
		}
		.block-block-front-top-right-block {
			padding: 40px 15px;
			p {
				color: $off-white;
				font-size: $tiny-font;
				line-height: 26px;
				margin: 0;
			}
			@include md {
				padding: 0 30px 40px;
			}
		}
	}
	.block-system-breadcrumb {
		.breadcrumb {
			background: none;
			border-radius: 0;
			margin: 0;
		}
	}
}
main {
	margin: 50px 0;
	transition: 0.5s;
}
.layout--boxton:not(.layout-front) {
	main {
		.l-wrapper {
			.container {
				padding: 0 144px;
				@include md {
					padding: 0 15px;
				}
			}
		}
	}
}
.front main {
	margin: 0;
}
.donate_em {
	margin-bottom: 30px;
	margin-top: 50px;
	em {
		font-size: 22px;
	}
}