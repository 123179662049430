form {
	.form-item {
		margin-bottom: 30px;
		label {
			font-size: $x-tiny-font;
			font-weight: normal;
			.form-required {
				text-decoration: none;
			}
		}
		.form-text,
		.description,
		.form-textarea-wrapper {
			width: 50%;
		}
		.form-number {
			width: auto;
		}
	}
}