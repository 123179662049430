.testimonial_wrapper {
	.testimonial_item {
		border-bottom: 1px solid $black;
		padding: 30px 0;
		p {
			color: $black;
			font-family: $georgia_italic;
			font-size: $x-small-font;
			margin: 0;
			a {
				font-family: sans-serif;
				font-size: $tiny-font;
				font-style: normal;
			}
		}
		span {
			font-size: $tiny-font;
		}
		&:last-child {
			margin-bottom: 30px;
		}
	}
}
.view-testimonials {
	.item-list {
		.pager {
			li {
				font-size: $tiny-font;
				a {
					color: $black;
				}
				&.pager-current {
					color: $light-blue;
					font-family: $OpenSansExtraBold;
				}
			}
		}
	}
}