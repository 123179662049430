footer.l-footer {
	.footer_wrapper {
		display: flex;
		width: 100%;
		.block {
			float: left;
			padding: 0 15px;
			width: 33.333333%;
			&.block-block-footer-contact {
				p {
					font-size: 12px;
					line-height: 18px;
					margin: 0;
					a {
						border: 1px solid $white;
						display: block;
						font-family: $OpenSans;
						font-size: $x-small-font;
						margin-bottom: 30px;
						padding: 10px 15px;
						text-align: center;
						text-decoration: none;
						width: 100%;
						@include md {
							display: inline-block;
							margin-bottom: 15px;
						}
					}
				}
				@include md {
					display: inline-block;
					order: 1;
					width: 100%;
				}
			}
			&.block-block-footer-newsletter {
				p {
					font-size: 12px;
					line-height: 18px;
					margin: 0;
					a {
						border: 1px solid $white;
						display: block;
						font-family: $OpenSans;
						font-size: $x-small-font;
						margin-bottom: 30px;
						padding: 10px 15px;
						text-align: center;
						text-decoration: none;
						width: 100%;
						@include md {
							display: inline-block;
							margin-bottom: 15px;
						}
					}
				}
			}
			&.block-block-social-media {
				position: relative;
				@include md {
					display: inline-block;
					order: 3;
					position: inherit;
					width: 100%;
				}
				.block-content {
					bottom: 0;
					position: absolute;
					width: calc(100% - 30px);
					@include md {
						position: inherit;
						width: 100%;
					}
					ul {
						margin: 0;
						padding: 0;
						text-align: center;
						li {
							display: inline-block;
							list-style-type: none;
							a {
								color: #95b7e1;
								font-size: $x-big-font;
								line-height: 1px;
							}
						}
					}
				}
			}
			&.block-block-footer-newsletter {
				@include md {
					margin-top: 30px;
					order: 2;
					.text-align-right {
						text-align: left;
					}
				}

			}
			@include md {
				text-align: left;
				width: 100%;
			}
		}
		@include md {
			flex-direction: column;
		}
	}
}