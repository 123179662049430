.tripoly_accordion {
	.card {
		border: none;
		border-radius: 0;
		border-top: 1px solid $black;
		.card-header {
			background: none;
			border: none;
			padding: 0;
			.btn-link {
				background: $light-bg;
				color: $light-blue;
				font-family: $OpenSansBold;
				font-size: $small-font;
				padding: 25px 25px 25px 19px;
				position: relative;
				text-align: left;
				text-decoration: none;
				width: 100%;
				&:before {
					border-bottom: 0;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 5px solid;
					content: "";
					display: inline-block;
					left: 0;
					position: absolute;
					top: 50%;
					top: calc(50% - 2.5px);
				}
				&.collapsed {
					background: none;
					color: $black;
					&:before {
						transform: rotate(-90deg);
					}
				}
			}
		}
		.collapse.show {
			background: $light-bg;
		}
		.card-body {
			padding: 20px;
			.field-content {
				color: $black;
				font-size: $tiny-font;
				line-height: 26px;
			}
		}
		&:first-child {
			// margin-top: 30px;
		}
		&:last-child {
			border-bottom: 1px solid $black;
			margin-bottom: 30px;
		}
	}
}