// Colors
$light-bg	:#f7fbfd;
$white		:#FFFFFF;
$off-white	:#e3e7f1;
$red		:#da1f3d;
$black		:#000000;
$gray		:#9E9E9E;
$blue		:#004781;
$light-blue	:#228ecb;
$sky-blue	:#84b2de;
$light-bg	:#F3F9FB;
$light-green :#89cfc9;
$icon-color	:#7dc1e3;

// Font Size
$x-big-font		:38px;
$big-font		:30px;
$medium-font	:24px;
$small-font		:19px;
$x-small-font	:18px;
$tiny-font		:16px;
$x-tiny-font	:14px;

// Width
$large-width    : 1200px;
$width-fluid	: 100%;

// Media Screen
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 320px;
// Small tablets (portrait view)
$screen-md-max: 767px;
// Tablets and small desktops
$screen-lg-max: 991px;
// Large tablets and desktops
$screen-xl-max: 1199px;

// Shadow
$shadow-default	:rgba(0,0,0, .9);