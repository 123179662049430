.primary_navigation_wrapper {
	.primary-navigation {
		transition: 0.5s;
		.block-content {
			@include md {
				// line-height: 0;
			}
		}
		.navbar {
			padding: 0;
			position: relative;
			top: -18px;
			.navbar-toggler {
				@include md {
					position: absolute;
					right: 15px;
					top: 15px;
				}
			}
			.navbar-collapse {
				.navbar-nav {
					.nav-item {
						.nav-link {
							color: $black;
							font-size: $tiny-font;
							padding: 5px 15px;
							&.active {
								color: $red;
							}
							&.dropdown-toggle.active {
								color: $black;
							}
							&:hover {
								color: $red;
							}
							@include md {
								padding: 5px 0;
							}
						}
						&.dropdown {
							.dropdown-menu {
								border: 0;
								border-radius: 0;
								box-shadow: 0 30px 15px 0px rgba(0, 0, 0, 0.2);
								margin-top: 0;
								padding: 0;
								padding-top: 25px;
								ul {
									display: block;
									.nav-item {
										padding: 5px 15px;
										width: 100%;
										.nav-link {
											font-size: $x-tiny-font;
											padding: 0;
										}
										@include md {
											margin-bottom: 0 !important;
										}
									}
								}
								@include md {
									box-shadow: none;
									margin: 0;
									padding-top: 0;
								}
							}
						}
						@include md {
							&:last-child {
								margin-bottom: 20px;
							}
						}
						&:hover {
							.dropdown-menu {
								display: block;
							}
						}
					}
				}
				@include md {
					background: $white;
					padding: 15px 30px;
					position: absolute;
					right: 0;
					top: 83px;
					width: 50%;
				}
			}
			@include lg {
				position: inherit;
			}
		}
	}
	@include md {
		position: inherit;
	}
}