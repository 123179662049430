header.l-header {
	background: $white;
	box-shadow: 0 0 10px rgba(0,0,0,0.2);
	left: 0;
	position: fixed;
	top: 0;
	transition: 0.5s;
	width: 100%;
	z-index: 9999;
	.logo-name-slogan-wrapper {
		position: relative;
		top: 10px;
		transition: 0.5s;
		.logo {
			img {
				max-height: 60px;
			}
		}
		@include md {
			position: static;
		}
	}
	.header-menu {
		transition: 0.5s;
		.menu {
			li {
				.active {
					color: $red;
				}
				a {
					color: $light-blue;
					font-size: 14px;
					text-transform: uppercase;
				}
				@include lg {
					padding: 0 5px;
				}
				@include md {
					margin-top: 15px;
					padding: 0 10px;
				}
			}
			@include md {
				padding: 10px 0;
			}
		}
		@include md {
			border-top: 1px solid $black;
			display: none;
			padding: 0;
			.navbar-nav {
				padding: 0;
				li {
					border: 0;
					margin-top: 5px;
					padding: 0;
					width: 100%;
					&:first-child {
						margin-top: 20px;
					}
				}
			}
		}
	}
	@include md {
		padding-top: 25px;
	}
	&.sticky-header {
		.logo-name-slogan-wrapper {
			padding: 5px 0 0;
		}
		.header-menu {
			padding: 15px 0 0;
		}
	}
}
.logged-in header.l-header {
	top: 33px;
	&.sticky-header {
		top: 0;
	}
}