.action_wrapper {
	.view-content {
		.views-row {
			border-bottom: 1px solid $black;
			padding: 50px 0;
			&:last-child {
				border: 0;
			}
			.action_img_title {
				text-align: center;
				img {
					height: 80px;
					max-width: 100%;
					width: auto;
				}
				h3 {
					color: $sky-blue;
					font-family: $OpenSansBold;
					font-size: $big-font;
					margin: 0;
					padding-top: 5px;
					a {
						color: $icon-color;
						&:hover {
							color: $icon-color;
							text-decoration: none;
						}
					}
				}
			}
			.action_body {
				font-size: $tiny-font;
				text-align: left;
				p {
					margin: 0;
					padding: 25px 0;
					a {
						color: $light-blue;
						font-family: $OpenSansSemibold;
						white-space: nowrap;
						&:hover {
							color: $light-blue;
							text-decoration: none;
						}
					}
					@include md {
						text-align: left;
					}
				}
			}
			&.even {
				.row {
					display: block;
					.action_img_title {
						float: right;
						text-align: center;
					}
					.action_body {
						text-align: right;
					}
				}
			}
		}
	}
}